import { t } from 'i18next';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import Router from 'vue-router';
import ErrorPage from '@/components/layout/pages/ErrorPage';
import MainPage from '@/components/layout/pages/MainPage';
import { fetchSettings, initCurrentUserAbilities } from './navigation-guards.utils';
import {
    ActionsRoutes,
    ActivityLogRoutes,
    AlertsRoutes,
    DashboardRoutes,
    DashboardsRoutes,
    DocumentsRoutes,
    HomeRoutes,
    ImportsRoutes,
    MetersRoutes,
    ReportsRoutes,
    SettingsRoutes,
    UsersRoutes,
} from './routes';

// Authentication

const SignIn = () => import(/* webpackChunkName: "authentication" */ '@/components/authentication/SignIn.vue');
const ForgetPassword = () =>
    import(/* webpackChunkName: "authentication" */ '@/components/authentication/ForgetPassword.vue');
const ResetPassword = () =>
    import(/* webpackChunkName: "authentication" */ '@/components/authentication/ResetPassword.vue');

const Profile = () => import(/* webpackChunkName: "profile" */ '@/pages/Profile.vue');

const Dashboard = () => import(/* webpackChunkName: "dashboards" */ '@/pages/Dashboard.vue');

/**
 * @typedef {Obj} Route
 * @property {string} name The name of the state
 * @property {string} path The path used by vue-router (https://router.vuejs.org/guide/essentials/dynamic-matching.html#advanced-matching-patterns)
 * @property {VueComponent} component The vue component
 *
 * An array of routes. Routes are compatible with vue-router format.
 * @type Route[]
 */
export const routes = [
    {
        name: 'home',
        path: '/',
        beforeEnter(to, from, next) {
            const isAuthenticated = Cookies.get('isAuthenticated');
            const customerCode = Cookies.get('customerCode');
            if (isAuthenticated && customerCode) {
                next({
                    name: 'customer',
                    params: {
                        customerCode,
                    },
                });
            } else {
                next({
                    name: 'signin',
                });
            }
        },
    },
    {
        name: 'signin',
        path: '/:customerCode?/signin',
        component: SignIn,
        meta: {
            isPublic: true,
        },
        props: (route) => ({ redirect: route.params.redirect, customerCode: getCustomerCodeFromRoute(route) }),
    },
    {
        name: 'share',
        path: '/s/:shareParam',
        component: Dashboard,
        meta: {
            isPublic: true,
        },
    },
    {
        name: 'forget-password',
        path: '/:customerCode?/forget-password',
        component: ForgetPassword,
        meta: {
            isPublic: true,
        },
        props: (route) => ({ customerCode: getCustomerCodeFromRoute(route) }),
    },
    {
        name: 'reset-password',
        path: '/:customerCode/reset-password',
        component: ResetPassword,
        meta: {
            isPublic: true,
        },
        props: (route) => ({ customerCode: getCustomerCodeFromRoute(route) }),
    },
    {
        name: 'customer',
        path: '/:customerCode',
        component: MainPage,
        async beforeEnter(to, from, next) {
            await fetchSettings();
            await initCurrentUserAbilities(getCustomerCodeFromRoute(to));
            next();
        },
        redirect: { name: 'customer.home' },
        children: [
            ActionsRoutes,
            ActivityLogRoutes,
            AlertsRoutes,
            DocumentsRoutes,
            HomeRoutes,
            ImportsRoutes,
            MetersRoutes,
            ReportsRoutes,
            SettingsRoutes,
            UsersRoutes,
            {
                name: 'customer.dashboards',
                path: 'dashboards',
                meta: {
                    title: 'DASHBOARDS_TITLE',
                },
                component: {
                    render: (h) => h('router-view'),
                },
                redirect: { name: 'customer.dashboards-list' },
                children: [DashboardsRoutes, DashboardRoutes],
            },

            {
                name: 'customer.perimeter',
                path: 'perimeter',
                redirect: {
                    name: 'customer.meters',
                },
                children: [
                    {
                        path: ':id',
                        redirect: { name: 'meter' },
                    },
                ],
            },
            {
                name: 'customer.profile',
                path: 'profile',
                component: Profile,
            },
        ],
    },
    {
        name: 'not-found',
        path: '/error/not-found',
        component: ErrorPage,
    },
    {
        path: '*',
        redirect: {
            name: 'not-found',
        },
    },
];

/**
 * Set `document.title` with `route.meta.title` on `router.afterEach`
 *
 * @param {Router} router The vue-router
 * @returns {Router}
 */
export function useMetaTitleAsDocumentTile(router) {
    router.afterEach((to) => {
        const title = get(to, 'meta.title');
        if (title) {
            document.title = t(title);
        } else {
            document.title = '';
        }
    });
    return router;
}
/**
 * Create the router
 *
 * @return {Router} The vue-router
 */
export function createRouter() {
    return new Router({
        mode: 'history',
        routes,
    });
}

/**
 * Return the current customerCode from route
 *
 * @param {*} route route
 * @returns {String} Customer code
 */
function getCustomerCodeFromRoute(route) {
    let customerCode = route.params.customerCode;
    if (!customerCode && location.hostname.endsWith('energiency.fr')) {
        customerCode = location.hostname.split('.')?.[0];
    }
    return customerCode;
}

function isLoggedIn() {
    return !!Cookies.get('isAuthenticated') && !!Cookies.get('customerCode');
}

// create a router once
let _router;
export function getRouter() {
    if (!_router) {
        _router = createRouter();
        _router.beforeEach((to, from, next) => {
            if (to.name === 'signin' && isLoggedIn()) {
                next({
                    name: 'home',
                    params: {
                        customerCode: to.params?.customerCode,
                    },
                });
            } else if (!to.meta?.isPublic && !isLoggedIn()) {
                const { name, params, query, fullPath } = to;
                next({
                    name: 'signin',
                    params: {
                        customerCode: params?.customerCode,
                        redirect: {
                            name,
                            params,
                            query,
                            fullPath,
                        },
                    },
                });
            } else {
                next();
            }
        });
        useMetaTitleAsDocumentTile(_router);
    }
    const push = Router.prototype.push;
    Router.prototype.push = function () {
        return push.apply(_router, arguments)?.catch((err) => {
            if (Router.isNavigationFailure(err)) {
                return err;
            }
            Promise.reject(err);
        });
    };
    return _router;
}
