import { useLocalStorage } from '@vueuse/core';

export const useLocalStorageClipboard = () => {
    const clipboardState = useLocalStorage('clipboard', {}, undefined, {
        serializer: {
            read: (v) => (v ? JSON.parse(v) : null),
            write: (v) => JSON.stringify(v),
        },
    });
    const copy = (newContent) => {
        clipboardState.value = newContent;
    };
    const clear = () => {
        clipboardState.value = null;
    };
    return { content: clipboardState, copy, clear };
};
