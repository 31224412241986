import { Ability, AbilityBuilder } from '@casl/ability';
import { t } from 'i18next';
import { keyBy } from 'lodash';
import { validate } from 'uuid';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { email as emailValidator, required, sameAs } from 'vuelidate/lib/validators';
import { availableLanguages } from '@/utils/languages.utils';
import { getLanguageLabel } from '@/helpers/locales';
import { useGetMe, useGetUsers } from '@/composables/queries/useUsersQueries';
import { useAbility } from '@/composables/useAbility';

export const ROLES = { READ: 'read', WRITE: 'write' };

export const ability = new AbilityBuilder(Ability).build();

export const useCurrentUser = () => {
    const { isPending: isPendingCurrentUser, data: currentUser } = useGetMe();

    const currentUserId = computed(() => currentUser.value?.id);

    return { currentUser, currentUserId, isPendingCurrentUser };
};

export const useUserEdition = ({ user, passwordInput }) => {
    const passwordLabels = {
        hidePassword: t('HIDE_PASSWORD'),
        showPassword: t('SHOW_PASSWORD'),
    };
    const validations = {
        lastName: {
            required,
        },
        firstName: {
            required,
        },
        login: {
            required,
        },
        email: {
            email: emailValidator,
        },
        password: {
            strong: (value) => !value || passwordInput.value?.$refs.component.passwordStrength >= 2,
        },
        repeatPassword: {
            sameAsPassword: sameAs('password'),
        },
        language: {},
    };

    const groupsLabel = computed(() => {
        return (
            Object.values(user.value.groups ?? {})
                .map(({ name }) => name)
                .join(', ') ?? '-'
        );
    });

    const languagesOptions = computed(() => {
        return [
            ...availableLanguages.map((language) => ({
                value: language,
                label: getLanguageLabel(language),
                selected: user.value.language === language,
            })),
            {
                value: 'automatic',
                label: t('AUTOMATIC_LANGUAGE'),
                selected: !user.value.language || user.value.language === 'automatic',
            },
        ];
    });

    const getEmailErrorMessage = ({ email }) => {
        if (!email) {
            return t('ERROR_FORMAT_EMAIL');
        }
        return null;
    };

    const getPasswordErrorMessage = (password) => {
        if (password.$dirty && !password.strong) {
            return t('ERROR_PASSWORD_STRONG');
        }
    };

    return {
        passwordLabels,
        validations,
        groupsLabel,
        languagesOptions,
        getEmailErrorMessage,
        getPasswordErrorMessage,
    };
};

export const useUserRights = ({ selectedUser }) => {
    const { can, subject } = useAbility();

    const canEdit = computed(() => can('update', subject('User', selectedUser.value)));
    const canChangeRole = computed(() => can('update', subject('User', selectedUser.value), 'role'));
    const canDelete = computed(() => can('delete', subject('User', selectedUser.value)));
    const canRead = (property) => can('read', subject('User', selectedUser.value), property);

    return { canEdit, canChangeRole, canDelete, canRead };
};

export const useUsersRoute = () => {
    const router = useRouter();

    const goToUsers = () => router.push({ name: 'customer.users' });
    const goToUser = (id) =>
        router.push({
            name: 'user-infos',
            params: { id },
            query: { ...router.currentRoute.query },
        });
    const goToCreateUser = () => router.push({ name: 'users-new' });
    return { goToUser, goToUsers, goToCreateUser };
};

export const useUsers = () => {
    const route = useRoute();
    const { isPending: isFetchingUsers, data } = useGetUsers();

    const users = computed(() => data.value ?? []);

    const usersById = computed(() => keyBy(users.value, 'id'));
    const selectedUserId = computed(() => route.params?.id);
    const selectedUser = computed(() => usersById?.value?.[selectedUserId.value]);

    const getUserFullName = (id) => {
        if (!validate(id)) {
            return '';
        }
        const user = usersById.value?.[id];
        return user ? `${user.firstName} ${user.lastName}` : t('UNKNOWN_USER');
    };

    return { users, isFetchingUsers, usersById, selectedUserId, selectedUser, getUserFullName };
};
