/**
 * New user service for use with Vue components (and VueX)
 */
import api from './api.config';

export const CREATE_USER_ALREADY_EXIST = 'CREATE_USER_ALREADY_EXIST';
export const UPDATE_USER_ALREADY_EXIST = 'UPDATE_USER_ALREADY_EXIST';
/**
 * @typedef {Object} User
 * @property {string} id                        Id of the user
 * @property {string} firstName                 first name
 * @property {string} lastName                  last name
 * @property {string} login                     login
 * @property {string} email                     email
 * @property {Object} groups
 * @property {string} role                      role (enum: 'reader', 'user', 'admin', 'superAdmin'')
 */

/**
 * @typedef {Object} UserProfile
 */

/**
 * Return the users resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function usersUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/users`;
}

/**
 * GET all users
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @returns {Promise<Array<User>>}
 */
export async function getUsers({ customerCode }) {
    try {
        const response = await api.get(usersUrl(customerCode));
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Create a user.
 *
 * @param {Object} params
 * @param {User} params.user the user to create
 * @returns {Promise<User>} the created user
 */
export async function createUser({ user, customerCode }) {
    try {
        user.email ||= null;
        const response = await api.post(usersUrl(customerCode), user);
        return response.data;
    } catch (error) {
        if (error?.response.status === 409) {
            throw CREATE_USER_ALREADY_EXIST;
        }
        throw new Error(error.response.data);
    }
}

/**
 * Get a user profile containing the name and the role associated to the user id
 *
 *
 * @param {Object} params
 * @param {string} params.id The id of the user to get
 *
 * @returns {UserProfile}
 */
export async function getUserProfile({ id, customerCode }) {
    try {
        const response = await api.get(`${usersUrl(customerCode)}/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Update a user
 * @param {Object} params
 * @param {User} params.user The updated user
 * @returns {Promise<User>}
 */
export async function updateUser({ user, customerCode }) {
    user.email ||= null;
    if (!user.password) {
        delete user.password;
    }
    try {
        const response = await api.put(`${usersUrl(customerCode)}/${user.id}`, user);
        return response.data;
    } catch (error) {
        if (error?.response.status === 409) {
            throw UPDATE_USER_ALREADY_EXIST;
        }
        throw new Error(error.response.data);
    }
}

/**
 * Delete a user.
 *
 * @param {Object} params
 * @param {string} params.id The id of the user to delete
 */
export async function deleteUser({ id, customerCode }) {
    try {
        await api.delete(`${usersUrl(customerCode)}/${id}`);
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Get me (current user)
 * @param {string} customerCode Customer code
 * @returns {User} The current user
 */
export async function getCurrentUser({ customerCode }) {
    try {
        const response = await api.get(`${usersUrl(customerCode)}/me`);
        return response.data;
    } catch (error) {
        throw new Error(error?.response?.data);
    }
}
