import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import { changePassword, login, logout } from '@/api/auth.service';
import {
    CREATE_USER_ALREADY_EXIST,
    UPDATE_USER_ALREADY_EXIST,
    createUser,
    deleteUser,
    getCurrentUser,
    getUsers,
    updateUser,
} from '@/api/users.service';
import { updateAppLocale } from '@/utils/languages.utils';
import { notify } from '@/helpers/notifications';
import { confirmDeletion } from '@/store/utils/edition.utils';
import { useCurrentUser } from '@/components/users/composable';
import { usePostHog } from '../usePostHog';
import { useCustomerCode } from '@/composables/useCustomerCode';

export const useGetMe = () => {
    const customerCode = useCustomerCode();

    return useQuery({
        queryKey: ['current-user'],
        queryFn: () =>
            getCurrentUser({
                customerCode: customerCode.value,
            }),
    });
};

/**
 * Returns a composable to fetch users.
 *
 * @param {Object} options      vuequery options
 * @returns Composable
 *
 * @usage
 * ```
 * const { isPending, data: users } = useGetUsers();
 * ```
 */
export const useGetUsers = ({ options = {} } = {}) => {
    const customerCode = useCustomerCode();

    return useQuery({
        queryKey: ['users'],
        queryFn: async () =>
            getUsers({
                customerCode: customerCode.value,
            }),
        ...options,
    });
};

/**
 * Returns a composable to login a user.
 *
 * @returns Composable
 *
 * @usage
 * ```
 * const { isPending, mutateAsync: loginQuery } = useLoginQuery();
 * ```
 */
export const useLoginQuery = () => {
    return useMutation({
        mutationFn: ({ credentials, customerCode }) =>
            login({
                customerCode,
                credentials,
            }),
        onSuccess: (user, { customerCode, stayConnected }) => {
            Cookies.set('customerCode', customerCode, stayConnected ? { expires: 7 } : {});
            Cookies.set('isAuthenticated', true, stayConnected ? { expires: 7 } : {});
            updateAppLocale(user.language);
        },
        onError: (error) => {
            if (error.message !== 'ERR_CHANGE_PASSWORD_MESSAGE') {
                notify({ type: 'error', text: t('LOGIN_FAILURE_TEXT') });
            }
        },
    });
};

/**
 * Returns a composable to login a user.
 *
 * @returns Composable
 *
 * @usage
 * ```
 * const { isPending, mutateAsync: loginQuery } = useLoginQuery();
 * ```
 */
export const useChangePasswordQuery = () => {
    return useMutation({
        mutationFn: ({ credentials, newPassword }) => changePassword({ credentials, newPassword }),
        onSuccess: (user, { credentials }) => {
            Cookies.set('customerCode', credentials.customerCode, { expires: 7 });
            Cookies.set('isAuthenticated', true, { expires: 7 });
            updateAppLocale(user.language);
        },
    });
};

/**
 * Returns a composable to logout a user.
 *
 * @returns Composable
 *
 * @usage
 * ```
 * const { isPending, mutateAsync: logout } = useLogout();
 * ```
 */
export const useLogout = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => logout(),
        onSuccess: () => {
            Cookies.remove('isAuthenticated');
            Cookies.remove('customerCode');
            updateAppLocale();
            queryClient.invalidateQueries({ queryKey: ['current-user'] });
        },
    });
};

/**
 * Returns a composable to create a new user.
 *
 * @returns Composable
 *
 * @usage
 * ```
 * const { mutateAsync: createUser } = useCreateUser();
 * ```
 */
export const useCreateUser = () => {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();
    const { capture } = usePostHog();

    return useMutation({
        mutationFn: (newUser) =>
            createUser({
                customerCode: customerCode.value,
                user: newUser,
            }),
        onSuccess: async (user) => {
            capture('create user', {
                name: `${user.firstName} ${user.lastName}`,
                customerCode: customerCode.value,
            });
            queryClient.invalidateQueries({ queryKey: ['users'] });
            notify({ type: 'success', text: t('CREATE_USER_SUCCESS') });
        },
        onError(error) {
            const errorMessage =
                error === CREATE_USER_ALREADY_EXIST ? t('USER_ALREADY_EXIST_ERROR') : t('CREATE_USER_ERROR');
            notify({ type: 'error', text: errorMessage });
        },
    });
};

/**
 * Returns a composable to update a user.
 *
 * @returns Composable
 *
 * @usage
 * ```
 * const { mutateAsync: updateUser } = useUpdateUser();
 * ```
 */
export const useUpdateUser = () => {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();
    const { currentUserId } = useCurrentUser();

    return useMutation({
        mutationFn: (user) =>
            updateUser({
                customerCode: customerCode.value,
                user,
            }),
        onSuccess: (user) => {
            queryClient.invalidateQueries({ queryKey: ['users'] });
            if (user.id === currentUserId.value) {
                updateAppLocale(user.language);
                queryClient.invalidateQueries({ queryKey: ['current-user'] });
            }
            notify({
                type: 'success',
                text: t('USER_UPDATE_SUCCESS'),
            });
        },
        onError: (error) => {
            const errorMessage =
                error === UPDATE_USER_ALREADY_EXIST ? t('USER_ALREADY_EXIST_ERROR') : t('USER_UPDATE_ERROR');
            notify({
                type: 'error',
                text: errorMessage,
            });
        },
    });
};

/**
 * Returns a composable to delete a user.
 *
 * @returns Composable
 *
 * @usage
 * ```
 * const { mutateAsync: deleteUser } = useDeleteUser();
 * ```
 */
export const useDeleteUser = () => {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (user) => {
            if (!(await confirmDeletion())) {
                return false;
            }
            return deleteUser({ customerCode: customerCode.value, id: user.id });
        },
        onSuccess: (data, user) => {
            if (data === false) {
                return;
            }
            // Invalid all users key expect deleted item (prevent 404 when side panel open)
            notify({ type: 'success', text: t('DELETE_USER_SUCCESS') });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'users' && query.queryKey[1] !== user.id,
            });
        },
        onError: () => {
            notify({ type: 'error', text: t('DELETE_USER_ERROR') });
        },
    });
};
