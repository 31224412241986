import { subject } from '@casl/ability';
import { abilitiesPlugin } from '@casl/vue';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
// Sentry
import * as Sentry from '@sentry/vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import i18next, { init } from 'i18next';
import I18NextVue from 'i18next-vue';
import moment from 'moment';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import Notifications from 'vue-notification';
import Router from 'vue-router';
import { sync } from 'vuex-enhanced-router-sync';
import { getCleanLanguage, i18nStrings, supportedLanguages } from '@/utils/languages.utils';
import { posthogClient } from '@/helpers/posthog';
// App
import { getRouter } from '@/router';
import { getStore } from '@/store';
import App from '@/components/App.vue';
import { ability } from './components/users/composable';

require('./assets/images/logo.png');
require('./assets/images/google-logo.svg');
require('./assets/images/logo-white.png');
require('./assets/images/logo-white-full.png');
require('./assets/images/cloud.svg');

require('file-loader?name=favicon.ico!./favicon.ico');
require('file-loader?name=robots.txt!./robots.txt');

require('./app/app.less');

let _unsync;
const router = getRouter();

init({
    lng: getCleanLanguage(window.navigator.userLanguage ?? window.navigator.language),
    supportedLngs: supportedLanguages,
    resources: i18nStrings,
    lowerCaseLng: true,
});

moment.defineLocale('en-be', {
    parentLocale: 'en',
    week: {
        dow: 1, // Monday is the first day of the week
        doy: 4, // The week that contains Jan 4th is the first week of the year
    },
});

const store = getStore();

Vue.use(Router);
Vue.use(Notifications);
Vue.use(VueQueryPlugin, {
    queryClientConfig: {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    },
});

Vue.use(abilitiesPlugin, ability);
Vue.prototype.$subject = subject;

/**
 * Create and mount the root Vue instance of the App
 */
function createApp() {
    if (!_unsync) {
        _unsync = sync(store, router);
    }

    Vue.use(I18NextVue, { i18next });

    const app = new Vue({
        router,
        store,
        el: '#vue-app',
        components: { App },
        template: '<App/>',
        provide: {
            posthog: posthogClient,
        },
    });

    return app;
}

createApp();

// Sentry configuration
if (process.env.ENABLE_SENTRY && process.env.SENTRY_DSN && process.env.VERSION) {
    Sentry.init({
        Vue,
        dsn: process.env.SENTRY_DSN,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['localhost', /^\//],
            }),
            new CaptureConsoleIntegration({
                levels: ['error'],
            }),
        ],
        tracesSampleRate: 1.0,
        release: process.env.VERSION,
        environment: getEnvironment(),
    });
}

function getEnvironment() {
    if (location.hostname.endsWith('ci.energiency.fr')) {
        return 'staging';
    } else if (location.hostname.endsWith('energiency.fr')) {
        return 'production';
    }
    return 'development';
}
