<template>
    <UiThemeProvider class="w-100 h-100" :colors="{ main: mainColor }">
        <div>
            <router-view />
            <Notification />
        </div>
    </UiThemeProvider>
</template>

<script>
import { mapGetters } from 'vuex';
import { NAMESPACE as NS_SETTINGS } from '@/store/modules/settings';
import { UiThemeProvider, colors } from '@energiency/ui';
import Notification from '@/components/layout/Notification';
import { useLocalStorageClipboard } from '@/composables/useLocalStorageClipboard';

export default {
    components: {
        Notification,
        UiThemeProvider,
    },
    setup() {
        const { copy, clear } = useLocalStorageClipboard();
        const onStorageUpdate = (event) => {
            if (event && event.key === 'clipboard') {
                if (event.newValue) {
                    copy(event.newValue);
                } else {
                    clear();
                }
            }
        };
        return { onStorageUpdate };
    },
    computed: {
        ...mapGetters(NS_SETTINGS, ['appearance']),
        mainColor() {
            return this.appearance?.mainColor ?? colors.primary;
        },
    },
    created() {
        window.addEventListener('storage', this.onStorageUpdate, false);
    },
    destroyed() {
        window.removeEventListener('storage', this.onStorageUpdate);
    },
};
</script>
