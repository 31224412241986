<template>
    <div class="MainPage w-100 h-100 flex flex-column">
        <Navbar />
        <div class="w-100 flex flex-auto">
            <Sidebar />
            <div class="page-content flex-auto">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch } from 'vue';
import { ability, useCurrentUser } from '@/components/users/composable';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';

const { currentUser } = useCurrentUser();

watch(currentUser, () => {
    ability.update(currentUser.value?.rules ?? []);
});
</script>
