import { subject } from '@casl/ability';
import moment from 'moment-timezone';
import { getCurrentUser } from '@/api/users.service';
import { DATE_RANGE_TYPES } from '@/utils/dashboard.utils';
import { getRelativeDateRange } from '@/utils/date-ranges.utils';
import { updateAppLocale } from '@/utils/languages.utils';
import { getStore } from '@/store';
import { NAMESPACE as NS_SETTINGS } from '@/store/modules/settings';
import { FETCH_SETTINGS } from '@/store/modules/settings/action-types';
import { actionTypes as queryParamsActionTypes } from '@/store/reusable-modules/query-params.module';
import { ability } from '@/components/users/composable';

function getNewDateRangeQueryParam(startDate, endDate, lastQueryParams = {}, defaultDateRange = {}, timezone) {
    if (!(startDate && endDate && moment(startDate).isValid() && moment(endDate).isValid())) {
        if (lastQueryParams.startDate && lastQueryParams.endDate) {
            return lastQueryParams;
        }
        const today = { type: DATE_RANGE_TYPES.RELATIVE, unit: 'day', offset: 0 };
        const dateRange = defaultDateRange.type ? defaultDateRange : today;
        return getDateRange(dateRange, timezone);
    }
    return null;
}

function getNewTimeStepQueryParam(startDate, endDate, timestep, lastQueryParams = {}) {
    const store = getStore();
    if (!store.getters[`${NS_SETTINGS}/isTimestepAllowed`](timestep, startDate, endDate)) {
        if (store.getters[`${NS_SETTINGS}/isTimestepAllowed`](lastQueryParams.timestep, startDate, endDate)) {
            return lastQueryParams.timestep;
        }
        return store.getters[`${NS_SETTINGS}/getIdealTimestep`](startDate, endDate);
    }
    return null;
}

/**
 * Validate `startDate` and `endDate` query params. If query params are invalid, `lastQueryParams` is used to get query params, otherwise defaults are set.
 *
 * @param {*} to
 * @param {*} from
 * @param {*} lastQueryParams
 * @param {object} defaultDateRange by default today
 * @param {string} timezone
 * @returns {object} The redirection params to call `next` with, otherwise null.
 */
export function getDatesBeforeRoute(
    to,
    from,
    lastQueryParams = {},
    defaultDateRange = {},
    timezone = moment.tz.guess(),
) {
    let { startDate, endDate } = to.query;

    const newDateRange = getNewDateRangeQueryParam(startDate, endDate, lastQueryParams, defaultDateRange, timezone);
    if (newDateRange) {
        startDate = newDateRange.startDate;
        endDate = newDateRange.endDate;
    }

    if (newDateRange) {
        return {
            path: to.path,
            query: {
                ...to.query,
                startDate: moment(startDate).tz(timezone).toISOString(),
                endDate: moment(endDate).tz(timezone).toISOString(),
            },
            replace: true,
        };
    }
    return null;
}

/**
 * Validate `startDate`, `endDate` and `timestep` query params. If query params are invalid, `lastQueryParams` is used to get query params, otherwise defaults are set.
 *
 * @param {*} to
 * @param {*} from
 * @param {*} lastQueryParams
 * @param {*} defaultDateRange by default today
 * @param {*} timezone
 * @param {*} defaultTimestep
 * @returns {object} The redirection params to call `next` with, otherwise null.
 */
export function getDatesAndTimestepBeforeRoute(
    to,
    from,
    lastQueryParams = {},
    defaultDateRange = {},
    timezone = moment.tz.guess(),
    defaultTimestep = undefined,
) {
    let { startDate, endDate } = to.query;
    const newDateRange = getNewDateRangeQueryParam(startDate, endDate, lastQueryParams, defaultDateRange, timezone);
    if (newDateRange) {
        startDate = newDateRange.startDate;
        endDate = newDateRange.endDate;
    }

    let timestep = to.query.timestep ? to.query.timestep : defaultTimestep;
    const newTimeStep = getNewTimeStepQueryParam(startDate, endDate, timestep, lastQueryParams);
    if (newTimeStep) {
        timestep = newTimeStep;
    }

    if (startDate !== to.query.startDate || endDate !== to.query.endDate || timestep !== to.query.timestep) {
        return {
            path: to.path,
            query: {
                ...to.query,
                startDate: moment(startDate).toISOString(),
                endDate: moment(endDate).toISOString(),
                timestep: timestep,
            },
            replace: true,
        };
    }
    return null;
}

export async function fetchSettings() {
    const store = getStore();
    await store.dispatch(`${NS_SETTINGS}/${FETCH_SETTINGS}`, {});
}

export async function initCurrentUserAbilities(customerCode) {
    try {
        const currentUser = await getCurrentUser({
            customerCode,
        });
        if (currentUser) {
            updateAppLocale(currentUser.language);
            ability.update(currentUser.rules);
        }
    } catch (e) {
        return;
    }
}

export function getDateRange(dateRange, timezone) {
    if (dateRange?.type === DATE_RANGE_TYPES.RELATIVE) {
        return getRelativeDateRange(dateRange.offset, dateRange.unit, timezone);
    }

    if (dateRange?.type === DATE_RANGE_TYPES.ABSOLUTE) {
        return {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
        };
    }

    return {};
}

export function applyQueryParams(NAMESPACE) {
    return (to, from, next) => {
        const store = getStore();
        const lastQueryParams = store.state[NAMESPACE].queryParams.lastQueryParams || {};
        const redirect = getDatesBeforeRoute(to, from, lastQueryParams);
        if (redirect !== null) {
            next(redirect);
        } else {
            store.dispatch(`${NAMESPACE}/${queryParamsActionTypes.SAVE_QUERY_PARAMS}`, to.query);
            next();
        }
    };
}

export async function hasRessourceAccess(access, ressource, item, fields) {
    return ability.can(access, item ? subject(ressource, item) : ressource, fields);
}
